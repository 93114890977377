























































































































import Vue from "vue";
import _ from "lodash";
import store from "../../store/session";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import { ClientOrder } from "../../model/entity/client_order";
import notificationMixin from "../../mixin/notification";
import moment from "moment";

const translate = TranslateServiceBuilder.Instance()._;
const ModalPopup = () => import("../elements/QModal.vue");
const MenuDetailPopup = () => import("../compositions/SOMenuDetailPopup.vue");
let menuPopup;

export default Vue.component("SOCart", {
  components: {
    QTranslate: () => import("../elements/QTranslate.vue"),
    QSkeleton: () => import("../elements/QSkeleton.vue"),
    QButton: () => import("../elements/QButton.vue"),
    QAlert: () => import("../elements/QAlert.vue"),
    QError: () => import("../elements/QError.vue")
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  mixins: [notificationMixin],
  data() {
    return {
      toggleOpen: false,
      errMessage: "",
      isDeliver: "0",
      selectPopup: false,
    };
  },
  watch: {
    orders: {
      deep: true,
      handler() {
        if (this.orders.length === 0) {
          this.close();
        }
      }
    },
    "$route.hash"() {
      if (menuPopup && !this.$route.hash) {
        menuPopup.remove();
        menuPopup = null;
        this.open();
      }
    }
  },
  computed: {
    errors() {
      return store.state.errors || {};
    },
    orders() {
      const orders = store.state.cart.orders;
      this.errMessage = ''; // clear msg when update order
      return orders
    },
    cartStyle(): object {
      if (this.isOpen) {
        return {
          display: "block"
        };
      }

      return {
        display: "none"
      };
    },
    cartClass(): object {
      return {
        open: this.isOpen
      };
    },
    cartAreaClass(): string {
      if (this.isOpen) {
        return "Title mb__iphone-x-bottom open";
      }

      return "Title mb__iphone-x-bottom";
    },
    last_order_time() {
      let result = null;

      _.map(
        store.state.shop.last_order_times, function (item) {
          let current = moment.duration(moment().format("HH:mm"));
          let last_order_time = moment.duration(item.time);
          let difference = last_order_time.subtract(current);

          if (0 <= difference.asMinutes() && difference.asMinutes() <= 10) {
            result = item.time;
          }
        });

      return result;
    },
  },
  methods: {
    handleClickOpenButton() {
      this.toggleOpen();
    },
    handleClickToggleCart() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    handleClickOrder() {
      if (store.state.isOrdering) {
        return;
      }
      if (store.state.shop.is_select_receive) {
        this.openSelectPopup();
      } else {
        this.submitOrder();
      }
    },
    handleClickCloseModal: function () {
      this.closeSelectPopup();
    },

    submitOrder: function () {
      this.errMessage = "";
      this.closeSelectPopup();
      this.$emit("close");
      store.dispatch("postOrder", this.isDeliver).catch((err: Error) => {
        this.showErrorNotification(err.message);
        this.open();
        this.errMessage = err.message;
      });
    },

    openSelectPopup() {
      this.isDeliver = "0";
      this.selectPopup = true;
    },
    closeSelectPopup() {
      this.selectPopup = false;
    },

    menuName(key: string) {
      const menu = store.state.cacheMenus[key];
      if (!menu) {
        return null;
      }

      return _.cloneDeep(menu).name;
    },
    menuAlcohol(key: string): boolean {
      const menu = store.state.cacheMenus[key];
      if (!menu) {
        return false;
      }

      return _.cloneDeep(menu).is_liquor_tax;
    },
    menuOptionName(key: string) {
      const menuMenusOption = store.state.cacheMenusMenuOption[key];
      if (!menuMenusOption) {
        return null;
      }

      const menuOption =
        store.state.cacheMenuOptions[
          _.cloneDeep(menuMenusOption).menu_option_id
        ];
      if (!menuOption) {
        return null;
      }

      return _.cloneDeep(menuOption).title;
    },
    menuMenusOptionName(key: string) {
      const menuMenusOption = store.state.cacheMenusMenuOption[key];
      if (!menuMenusOption) {
        return null;
      }

      return _.cloneDeep(menuMenusOption).menu.name;
    },

    close() {
      this.$emit("close");
    },
    open() {
      this.$emit("open");
    },
    handleClickEditOrder(index: number, order: ClientOrder) {
      this.showMenuDetail(order.id, index, order);
    },
    showMenuDetail(menuId: string, index: number, order: ClientOrder) {
      MenuDetailPopup().then(Popup => {
        if (menuPopup) {
          menuPopup.remove();
          menuPopup = null;
        }
        const optionids = _.map(order.options, opt => {
          return opt.option_id;
        });

        menuPopup = new Popup.default({
          propsData: {
            parent: this.$parent.$el,
            isOpen: true,
            allowRemove: true,
            menuId,
            order: {
              id: menuId,
              quantity: order.quantity,
              options: optionids
            }
          }
        });
        menuPopup.$on("update", order => {
          store.dispatch("addOrder", {
            order: {
              id: order.menuId,
              quantity: order.quantity,
              options: _.map(order.options, opt => {
                return {
                  option_id: opt,
                  quantity: order.quantity
                };
              })
            },
            index
          });
        });

        menuPopup.$on("remove", opt => {
          store.dispatch("removeOrder", {
            index
          });
        });

        menuPopup.$on("close", () => {
          this.$router.push(`#`);
          this.open();
        });

        menuPopup.$mount();
        this.close();
        this.$router.push(`#open`);
      });
    },
    isDisableOrder(order) {
      let result = false;

      if (order.length < 1) {
        return true;
      }

      _.map(
        store.state.shop.last_order_times, function (item) {
          let last_order_time = item.time;
          let release_time = moment(item.time, "HH:mm").add(item.release_time, 'minutes').format("HH:mm");
          let current = moment().format("HH:mm");

          if (last_order_time <= current && current <= release_time) {
            result = true;
          }
        });

      return result
    }
  }
});
