import Vue from 'vue';

const QNotification = () => import('../components/elements/QNotification.vue');

export default Vue.extend({
	methods: {
		showSuccessNotification(message: string) {
			this._openNotification(message, 'success');
		},
		showErrorNotification(message: string) {
			this._openNotification(message, 'error');
		},
		_openNotification(message: string, status: string) {
			QNotification().then((QNotify) => {
				const qnotify = new QNotify.default({
					propsData: {
						parent: this.$parent.$el,
						message,
						status
					}
				});

				qnotify.$mount();
			});
		}
	}
});
